.container {
    padding: 20px;
}

.header {
    text-align: center;
}

.destinations {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.destination-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 200px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s;
}

.destination-card.loading {
    opacity: 0.5;
    pointer-events: none;
}

.destination-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.destination-info h3 {
    font-size: 1.2rem;
    margin: 10px 0;
}

.destination-info .rating {
    color: #ffcc00;
    font-size: 1rem;
}

.destination-info p {
    font-size: 1rem;
}

.destination-info span {
    font-weight: bold;
    color: #28a745;
}

.loading-spinner {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #666;
}

/* Mobile view styles */
@media (max-width: 768px) {
    .destinations {
        flex-direction: column;
        align-items: center;
    }

    .destination-card {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .destination-card img {
        display: none;
    }

    .destination-info h3 {
        font-size: 1rem;
        margin: 0;
    }

    .destination-info {
        flex: 1;
        padding-left: 10px;
    }

    .destination-info .rating,
    .destination-info p {
        font-size: 0.9rem;
    }
}

.no-flights-found-container {
    position: relative;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #b6b7c1, #88ddd3);
    z-index: -1;
  }
  
  .content {
    text-align: center;
    color: rgb(50, 50, 50);
    z-index: 1;
  }
  
  .content h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .content p {
    font-size: 1.2rem;
  }
  .content img {
    max-width: 100%;
  }
  
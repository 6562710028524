/* General Styles */
.user-details-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.important-note {
  background-color: #ffe4c4;
  border: 1px solid #ffcc99;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 0.9em;
}

/* Flight Details */
.flight-details {
  margin-bottom: 20px;
}

.flight-banner {
width: 100%;
max-width: 1400px;
}

/* Passenger Info */
.passenger-info {
  margin-bottom: 20px;

}

.passenger-details {
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
  
}

.passenger-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.traveler-number {
  font-weight: bold;
}

.traveler-type {
  font-style: italic;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 120px;
}

.input-field  input[type="text"],
input[type="email"],
input[type="tel"],
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.dob-selectors {
  display: flex;
  gap: 5px;
  overflow: hidden; /* Prevents content from overflowing outside the container */
}

.dob-selectors select {
  flex-grow: 1;
}


/* Contact Info */
.contact-info {
  margin-bottom: 20px;
}

.contact-info h2 {
  margin-top: 30px;
}

/* Submit Button */
.submit-btn {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 20px;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 600px) {
  .input-group {
      flex-direction: column;
  }

  .input-field {
      width: 100%;
  }
}


























/* Flight Card Styles */
.flight-cardss {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  max-width: 1400px;
}

/* Header Styling */
.flight-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
}

.flight-typse {
  position: relative;
  left: 0;
  top: 0;
  font-size: 14px;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
}



.flight-nam {
  font-size: 15px;
  font-weight: bold;
}

.flight-pri {
  font-size: 15px;
  font-weight: bold;
  color: blue;
  /* text-align: right; */
}

/* Flight Timing Details */
.flight-timing {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  margin: px 0;
}

.flight-start, .flight-end {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.flight-end {
  text-align: right;
}

.flight-date {
  font-size: 14px;
  color: #666;
}

.flight-time {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0;
}

.flight-location, .flight-terminal {
  font-size: 14px;
  color: #999;
}

.flight-duration {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #333;
  width: 10%;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .flight-card {
      padding: 10px;
  }

  .flight-header {
      flex-direction: column;
      align-items: flex-start;
  }

  .flight-type {
      position: static;
      margin-bottom: 10px;
  }

  .airline-logo {
      margin: 0 auto 10px;
  }

  .flight-name {
      margin: 10px 0;
  }

  .flight-price {
      position: static;
      margin-top: 10px;
      text-align: left;
  }

  .flight-timing {
      flex-direction: row;
  }

  .flight-start, .flight-end {
      width: 100%;
  }

  .flight-duration {
      margin: 10px 0;
      width: 100%;
      font-size: 10px;
  }

  .flight-duration ,.flight-date, .flight-time, .flight-location, .flight-terminal {
      font-size: 12px;
  }
}

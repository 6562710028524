/* Spanish Component CSS */
.spaincta-container {
    background: white;
    padding: 20px;
    text-align: center;
    display: block; /* Default: Visible */
  }
  
  /* Hide the component on screens larger than 768px (Tablet & Desktop) */
  @media (min-width: 769px) {
    .spaincta-container {
      display: none;
    }
  }
  
  .spaincta-header {
    background: #f1c40f;
    color: black;
    font-weight: bold;
    font-size: 1rem;
    padding: 10px;
    border-radius: 10px;
  }
  
  .spaincta-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
  }
  
  .spaincta-button {
    background: #004b87;
    color: white;
    padding: 10px;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .spaincta-button:hover {
    transform: scale(1.05);
  }
  
  .spaincta-info {
    margin-top: 20px;
  }
  
  .spaincta-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .spaincta-benefits {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .spaincta-benefit {
    display: flex;
    align-items: center;
    font-size: .8rem;
  }
  
  .spaincta-divider {
    border-top: 2px solid red;
    margin: 10px 0;
  }
  
  .spaincta-call-section {
    margin-top: 20px;
  }
  
  .spaincta-agent-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .spaincta-wait-time {
    font-size: .9rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .spaincta-call-link {
    color: red;
    font-weight: bold;
    font-size: 1.1rem;
    display: block;
    margin-top: 5px;
  }
  
  .spaincta-phone {
    background: #004b87;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
    margin-top: 15px;
  }
  
  .spaincta-footer {
    margin-top: 15px;
    color: #555;
    font-size: 0.9rem;
  }
  

/* General Container */
.spanish-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  /* Section: ¿Por qué nosotros? */
  .spanish-why-us {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .spanish-heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .spanish-why-us-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .spanish-image {
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
  }
  
  .spanish-benefits {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .spanish-benefit {
    text-align: left;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .spanish-icon {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  /* Section: Reserva un vuelo */
  .spanish-book-flight {
    margin-bottom: 40px;
  }
  
  .spanish-subheading {
    font-size: 1.75rem;
    margin-bottom: 15px;
  }
  
  .spanish-paragraph {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
  }
  
  .spanish-features,
  .spanish-services {
    margin: 0;
    padding-left: 20px;
    list-style: disc;
  }
  
  .spanish-features li,
  .spanish-services li {
    margin-bottom: 10px;
  }
  
  /* Section: Destino popular */
  .spanish-destinations {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .spanish-destination-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .spanish-destination {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
  }
  
  .spanish-destination-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .spanish-destination p {
    margin: 10px 0;
    font-size: 1rem;
    font-weight: bold;
  }


  /* Spanish.js Component Styles */
.spain-text-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.spain-text-heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #003366;
  margin-bottom: 15px;
}

.spain-text-paragraph {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.spain-text-subheading {
  font-size: 20px;
  font-weight: bold;
  color: #0056b3;
  margin-top: 20px;
  margin-bottom: 10px;
}

.spain-text-list {
  list-style: none;
  padding: 0;
}

.spain-text-list li {
  font-size: 16px;
  padding: 8px;
  background-color: #e6f2ff;
  border-left: 4px solid #0056b3;
  margin-bottom: 5px;
  color: #333;
}

.spain-text-footer {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #003366;
  margin-top: 20px;
}

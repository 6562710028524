.about_us {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video fills the container without cropping */
}

#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: rgb(0, 0, 0);
  padding: 20px;
  font-size: large; /* Smaller font size */
}

.about_us_se {
  text-align: left;
  max-width: 1500px; /* Set maximum width */
  margin: 0 auto 0px; /* Center the section and add bottom margin of 50px */
  padding: 0 10px; /* Add padding to maintain spacing */
}

.about_us_container {
  max-width: 1500px;
  margin: 0 auto;
}

.about_us_section {
  background-color: rgb(255, 255, 255); /* Set background color to white */
  color: rgb(0, 0, 0); /* Set text color to black */
  padding: 10px 0; /* Add padding for spacing */
}

.about_us_banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about_us_banner_content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.about_us_banner_image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.about_us_banner_heading {
  margin-top: 0;
  text-align: center;
  font-size: large; /* Smaller font size */
}

.about_us_banner_content p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: medium; /* Smaller font size */
}

.about_us_button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}

.about_us_button:hover {
  background-color: #0056b3;
}

.about_us_banner_image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .about_us_banner {
    flex-direction: column;
  }

  .about_us_banner_content, .about_us_banner_image {
    max-width: 100%;
  }
}

.about_us_our_mission {
  text-align: left;
  max-width: 1500px; /* Set maximum width */
  margin: 0 auto 0px; /* Center the section and add bottom margin of 50px */
  padding: 0 10px; /* Add padding to maintain spacing */
}

.about_us_our_mission_section {
  background-color: rgb(255, 255, 255); /* Set background color to white */
  color: rgb(0, 0, 0); /* Set text color to black */
  padding: 10px 0; /* Add padding for spacing */
}

.about_us_our_mission_banner {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about_us_our_mission_banner_content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.about_us_our_mission_banner_image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.about_us_our_mission_banner_heading {
  margin-top: 0;
  text-align: center;
  font-size: large; /* Smaller font size */
}

.about_us_our_mission_banner_content p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: medium; /* Smaller font size */
}

.about_us_button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}

.about_us_button:hover {
  background-color: #0056b3;
}

.about_us_our_mission_banner_image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .about_us_our_mission_banner {
    flex-direction: column;
  }

  .about_us_our_mission_banner_content, .about_us_our_mission_banner_image {
    max-width: 100%;
  }
}

.about_us_our_vission {
  text-align: left;
  max-width: 1500px; /* Set maximum width */
  margin: 0 auto 0px; /* Center the section and add bottom margin of 50px */
  padding: 0 10px; /* Add padding to maintain spacing */
}

.about_us_our_vission_section {
  background-color: rgb(255, 255, 255); /* Set background color to white */
  color: rgb(0, 0, 0); /* Set text color to black */
  padding: 10px 0; /* Add padding for spacing */
}

.about_us_our_vission_banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about_us_our_vission_banner_content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.about_us_our_vission_banner_image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.about_us_our_vission_banner_heading {
  margin-top: 0;
  text-align: center;
  font-size: large; /* Smaller font size */
}

.about_us_our_vission_banner_content p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: medium; /* Smaller font size */
}

.about_us_button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}

.about_us_button:hover {
  background-color: #0056b3;
}

.about_us_our_vission_banner_image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .about_us_our_vission_banner {
    flex-direction: column;
  }

  .about_us_our_vission_banner_content, .about_us_our_vission_banner_image {
    max-width: 100%;
  }
}

/* Services Container */
.services-container {
    max-width: 1500px;
    margin: 0 auto;
    color: rgb(33, 35, 49);
    background-color: #ffffff;
  
  }
  .serv{
    background-color: #fffdfd;
    /* margin-bottom: 50px; */

  }
  
  /* Top Section */
  .top-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .left-section h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .right-section img {
    width: 100px;
    margin-left: 10px;
  }
  
  /* Service Cards Section */
  /* Service Cards Section */
  .service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto; /* Center horizontally */
  }
  
  
  
  
  /* Service Cards Section */
  .service-card {
    width: calc(45% - 10px); /* Two boxes per row for smaller screens */
    max-width: 300px;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    background-color: #0c1b2b;

  }
  
  
  .service-card img {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .service-card h3 {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 3px; /* Reduced margin bottom for the heading */
    margin-top: 0;
    text-align: center;
    color: #ddd;
  
  }
  
  .service-card p {
    margin-bottom: 5px; /* Reduced margin bottom for the paragraph */
    color: #ffffff; /* Lighter text color */
    margin-top: 0;
    text-align: justify;
    font-size: 12px;

  }
  
  .service-card button {
    background-color: #0c1b2b; /* Blue button color */
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    border: 0.1px solid #797979; /* Add sharp thin white color border line */
    margin-top: 15px;
  }
  
  /* Hover effect for cards */
  .service-card:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .top-section {
      flex-direction: column;
    }
  
    .right-section {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  
    .right-section img {
      margin: 0 10px;
    }
  
    .service-cards {
      grid-template-columns: 1fr; /* Single column layout for cards on smaller screens */
      margin: 10px; /* Reset margin for smaller screens */
    }
  
    .service-card {
      width: calc(100% - 10px); /* Two boxes per row for smaller screens */

      padding: 10px;

      border: 1px solid #ddd;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(42, 44, 73, 0.729);
      transition: all 0.3s ease;
      background-color: #0c1b2b;
    }
  
    
  }
  
.main-container {
  align-items: center;
  justify-content: center;
}

/* Styles for the tab container */
.flight-section-tabs {
  display: flex;
  padding: 20px;
  max-width: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* Styles for the tab */
.flight-section-tabs button {
  background-color: rgb(20, 19, 19);
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  max-width: 400px;
  width: 100%;
}

/* Styles for the active tab */
.flight-section-tabs button.active {
  /* background-color: #737171; */
  background-color: #0056b3;

  /* Change to desired color */

  border-radius: 5px 5px 0 0;
  border-color: #007bff;
  border-width: 5px;

}

/* Hover effect for the tab */
.bottom_card {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  /* Adjust background color as needed */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for visual effect */
  display: flex;
  justify-content: center;
}

.selected-flights-container {
  display: flex;
  flex-direction: column;
}

.flight-details_sect {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
}

.button {
  margin-top: 10px;
}

.left p{
  text-align: left;
}

.selected-flights-container {

  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.flight-details_sect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}

.left,
.right {
  width: 100%;
  border-color: #0056b3;
  border-width: 5px;
}

.airline-name {
  font-size: 16px;
  font-weight: bold;
}

.flight-times {
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.flight-times p {
  margin: 0 10px 0 0;
  font-size: 14px;
}

.arrow-icon {
  font-size: 28px;
}

.flight-price,
.total-price {
  font-weight: bold;
  margin-top: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}



.flight-cards-container{
  text-align: center;
  justify-content: center;
}


.flight-card{
  padding-bottom: 10px;
  margin-bottom: 10px;
}



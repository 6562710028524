.loading-container {
    text-align: center;
    padding: 20px;
    background-color: aliceblue;
    font-family: Arial, sans-serif;
    position: relative;
    width: 100%;
    margin: 0 auto; /* Center the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.flight-info h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}

.flight-info p {
    margin: 0.5em 0;
    font-size: 1em;
    color: #666;
}

.loading-text {
    font-size: 1.2em;
    margin: 20px 0;
}

.flight-animation {
    margin-top: 20px;
}

.flight-animation img {
    width: 100%;
    max-width: 200px;
    height: auto;
    animation: spin 2s linear infinite;
}


/* Media Queries for responsiveness */
@media (max-width: 600px) {
    .loading-container {
        padding: 10px;
    }

    .loading-text {
        font-size: 1em;
    }

    .flight-info h2 {
        font-size: 1.2em;
    }

    .flight-info p {
        font-size: 0.9em;
    }

    .flight-animation img {
        max-width: 150px;
    }
}


@media (max-width: 300px) {
    .loading-container {
        padding: 10px;
    }

    .loading-text {
        font-size: 1em;
    }

    .flight-info h2 {
        font-size: 1.2em;
    }

    .flight-info p {
        font-size: 0.9em;
    }

    .flight-animation img {
        max-width: 150px;
    }
}




.contact-number-container-loader-loading {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align content to the start (left) */
    background-color: #eff6ee;
    border: 2px solid #00b300;
    border-radius: 20px;
    padding: 5px 10px;
    max-width: fit-content; /* Limit the length */
    margin: 0 auto; /* Center the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for better aesthetics */
    transition: all 0.3s ease; /* Smooth transition for responsiveness */
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Inherit text color */
}

.image-container-call-loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-image-loading {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 3px solid #00b300; /* Increase border width for more visibility */
}

.phone-icon-loading {
    position: absolute;
    bottom: -5px; /* Adjust position as needed */
    right: -5px; /* Adjust position as needed */
    background-color: #ffffff;
    color: #00b300;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #00b300; /* Border for the phone icon */
}

.phone-number-loading {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
    white-space: nowrap; /* Prevent phone number from wrapping */
}

@media (max-width: 600px) {
    .contact-number-container-loader-loading {
        max-width: fit-content; /* Adjust width for mobile */
        padding: 10px 20px; /* Increase padding for better touch experience */
    }

    .profile-image-loading {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        border: 3px solid #00b300; /* Maintain increased border width for visibility */
    }

    .phone-number-loading {
        font-size: 18px; /* Adjust font size for mobile */
    }
}

/* Base styles for the component */
.bottom-bar {
    text-decoration: none;
    display: none; /* Initially hidden */
    align-items: center;
    background-color: #ffffff; /* Earthy Beige for background */
    color: #2C3E50; /* Dark Charcoal for text */
    padding: 5px 8px; /* Adjust padding */
    position: fixed;
    bottom: 10px;
    width: 100%;
    left: 0;
    border-radius: 50px; /* Slightly less round for a modern look */
    border: 2px solid #3498DB; /* Sky Blue border for visual emphasis */
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 
                0px 6px 20px rgba(0, 0, 0, 0.1),
                inset 0px 1px 2px rgba(255, 255, 255, 0.8); 
                text-decoration: none;
}

.popup-left, .popup-right {
    display: flex;
    align-items: center;
}

.popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Align content to the center */
    flex-grow: 1;
    text-align: center; /* Text centered */
    padding-top: 5px;
}

.agent-icon {
    width: 70px; /* Reduced size for a smaller image */
    height: 70px;
    margin-left: 10px;
}

.call-icon {
    width: 60px;
    height: 60px;
    background-color: #2ECC71; /* Forest Green for call buttons */
    border-radius: 50%;
    padding: 10px;
    margin-left: 15px;

    /* Shadow for the call icon */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.small-text {
    font-size: 14px;
    font-weight: bold;
    color: #2C3E50; /* Dark Charcoal for text */
    /* margin-bottom: 5px; */
    display: inline-block;

    /* Text shadow for depth */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    text-decoration: none;

}

.discount-box {
    background-color: #2ECC71; /* Forest Green for the discount box */
    color: white;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 5px;

    /* Shadow for the discount box */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    text-decoration: none;

}

.phone-number {
    font-size: 20px; /* Font size reduced to match */
    font-weight: bold;
    color: #3498DB; /* Sky Blue for phone number emphasis */

    /* Subtle text shadow */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

    /* Align phone number in the center */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}

.contact-numbers {
    text-decoration: none;
    /* color: #3498DB; Sky Blue for emphasis on phone number */
}

/* Mobile view styles */
@media (max-width: 768px) {
    .bottom-bar {
        display: flex; /* Make the bar visible on mobile */
    }

    .small-text {
        font-size: 12px;
    text-decoration: none;

    }

    .phone-number {
        font-size: 20px;
    text-decoration: none;

    }

    .discount-box {
        font-size: 12px;
        padding: 1px 4px;
    text-decoration: none;

    }

    .agent-icon, .call-icon {
        width: 60px;
        height: 60px;
    }

    .popup-left {
        height: 80px;
    }
}

/* Animation for flashing effect (optional) */
@keyframes flash {
    0%, 50%, 100% {
        opacity: 1;
    }
    25%, 75% {
        opacity: 0.5;
    }
}


a {
    text-decoration: none;
}
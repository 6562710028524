/* Container styles */
.privacy-policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Title styles */
  .privacy-policy-title {
    font-size: 32px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 20px;
    text-align: center;

  }
  
  /* Subtitle styles */
  .privacy-policy-subtitle {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 20px;
  }
  
  /* Paragraph styles */
  .privacy-policy-paragraph {
    margin-bottom: 15px;
    line-height: 1.5;
    text-align: justify;
    margin-left: 10px;
    margin-right: 20px;
  }
  
  /* Link styles */
  .privacy-policy-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy-link:hover {
    text-decoration: underline;
  }
  
  /* List styles */
  .privacy-policy-list {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .privacy-policy-list li {
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
  }
  
  /* Responsive styles */
  @media only screen and (max-width: 768px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    .privacy-policy-title {
      font-size: 24px;
    }
  
    .privacy-policy-subtitle {
      font-size: 20px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
  
    .privacy-policy-paragraph {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 12px;
    }
  
    .privacy-policy-list {
      margin-bottom: 12px;
      padding-left: 15px;
    }
  
    .privacy-policy-list li {
      margin-bottom: 4px;
    }
  }
  
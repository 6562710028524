.ecperdoa-2-1-cookie-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 5px 5px;
    font-size: 11px;
    border: 1px solid #ddd;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .ecperdoa-2-1-accept-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .ecperdoa-2-1-learn-more {
    color: #007bff;
    text-decoration: underline;
    font-size: 10px;
  }
  
  @media (min-width: 768px) {
    .ecperdoa-2-1-cookie-banner {
      display: none; /* Hide on larger screens */
    }
  }
  
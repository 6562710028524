*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.booking-wrapper {
  margin: 0 auto;
  text-align: center;
}

.booking-container {
  width: 100%;
  min-height: 80vh;
  background: linear-gradient(to bottom, #243b55, #141e30); /* Cooler, sharper gradient */
  background-image: url('../../Media/NewYorkAtlanta.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column; /* Ensures proper alignment for vertical elements */
  justify-content: center;
  align-items: center;
  padding: 2rem;
}


.booking-title {
  color: #f0f0f0;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.2;
  background-color: rgba(40, 44, 52, 0.85);
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  width: fit-content;
  margin: 0 auto 1.5rem auto;
  border: 2px solid rgba(40, 44, 52, 0.6);
}

.booking-card {
  background-color: rgb(72 72 72 / 73%);
  border-radius: 10px;
  padding: 1.5rem;
  color: #e2e8f0;
}

.booking-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.trip-type {
  display: flex;
  justify-content: left;
  gap: 2rem; /* Increased spacing for better alignment */
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #f0f0f0;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
}

.search-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.input-group {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 1rem; /* Ensure enough space from the input's edge */
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2rem; /* Increased size for better visibility */
  color: #10b981; /* Bright green color for visibility */
  z-index: 2; /* Ensure it appears above the input field */
}

.search-input {
  width: 100%;
  padding: 0.99rem 0.75rem 0.99rem 3rem; /* Adjust padding to account for the icon */
  border-radius: 0.375rem;
  border: 1px solid #3b82f6;
  background-color: #f9fafb;
}

.date-group {
  display: flex;
  gap: 1rem;
}

.date-group .input-group {
  flex: 1; /* Ensure equal width for date inputs */
}

.action-group {
  display: flex;
  gap: 1rem;
}

.search-button {
  padding: 0.75rem 1.5rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.search-button:hover {
  background-color: #2563eb;
  transform: scale(1.05);
}

.trust-badges {
  text-align: center;
  color: white;
  margin-top: 1.5rem;
}

.trust-badges p {
  margin-bottom: 0.5rem;
  background-color: rgba(40, 44, 52, 0.85);
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 8px;
}

.ratings {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.rating-group {
  display: flex;
  align-items: center;
  gap: 0rem;
  flex-direction: column;
}

.stars {
  color: #ffd700;
  font-size: 2rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .search-grid {
    grid-template-columns: 1fr 1fr;
  }

  .booking-title {
    font-size: 2rem;
  }
}

@media (max-width: 640px) {
  .search-grid {
    grid-template-columns: 1fr;
  }

  .date-group {
    flex-direction: column;
  }

  .action-group {
    flex-direction: column;
  }

  .ratings {
    flex-direction: column;
  }

  .booking-container {
    height: auto;
    padding: 1rem;
  }

  .booking-title {
    font-size: 1.75rem;
    line-height: 1.3;
  }
}








/* Content Styling */
.home-call-class-content {
  z-index: 2;
  padding: 20px 0px;
  /* margin-top: -10%;  */
}

.home-call-class-content h2 {
  color: #f0f0f0; /* Softer white for readability */
  font-size: 2.5rem; /* Make the title bigger */
  font-weight: 700; /* Bold for emphasis */
  margin-bottom: 1.5rem; /* Spacing below the title */
  text-align: center; /* Center the title text */
  line-height: 1.2; /* Better readability */
  background-color: rgb(0 3 14 / 95%); /* Darker transparent grey for better contrast */
  padding: 15px 20px; /* Add padding for better spacing inside the container */
  border-radius: 15px; /* Sharper rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  width: fit-content; /* Fit content width */
  margin: 0 auto 1.5rem auto; /* Center align the container */
  border: 2px solid rgba(40, 44, 52, 0.6); /* Subtle border for a polished look */
}


/* Button Styling */
.home-call-class-button {
display: inline-block;
background: linear-gradient(180deg, #d61a1a, #a10000); /* Glossy red gradient */
color: #fff;
padding: 18px 35px; /* Slightly larger padding for more emphasis */
text-decoration: none;
font-size: 2.5rem; /* Larger font size for laptop screens */
border-radius: 50px; /* More rounded button */
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
transition: background 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.home-call-class-button:hover {
background: linear-gradient(145deg, #a10000, #690000); /* Darker red gradient on hover */
transform: scale(1.05); /* Slight scale effect on hover */
box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}


.home-call-class-button:hover {
  background-color: #3a3a3a; /* Hover effect */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Responsive Design */

/* For Tablets and Laptops */
@media (max-width: 1024px) {
  .home-call-class-container {
    height: 50vh; /* Adjust height for smaller laptop/tablet screens */
  }

  .home-call-class-content {
    max-width: 500px;
    margin-top: -8%; /* Move content closer */
  }

  .home-call-class-content h2 {
    font-size: 2rem; /* Adjust for readability on medium screens */
    margin-bottom: 10px;
  }

  .home-call-class-button {
    font-size: 1.2rem;
    padding: 15px 30px;
  }
}

/* For Phones (Optimized for smaller screens) */
@media (max-width: 768px) {
  .home-call-class-container {
    height: 30vh; /* Reduce height for smaller screens */
  }

  .home-call-class-content {
    /* max-width: 90%; */
    margin-top: -5%; /* Move content even closer */
  }

  .home-call-class-content h2 {
    font-size: 1.7rem; /* Adjust font size for smaller screens */
    margin-bottom: 10px;
  }

  .home-call-class-button {
    font-size: 1.1rem;
    padding: 12px 25px;
  }
}

@media (max-width: 480px) {
  .home-call-class-container {
    height: 30vh; /* Further reduce the height */
  }

  .home-call-class-content {

    margin-top: -5%;
  }

  .home-call-class-content h2 {
    font-size: 1.4rem; /* Adjust for readability */
    margin-bottom: 8px;
  }

  .home-call-class-button {
    /* font-size: 1.5rem; */
    padding: 10px 20px; /* Further reduce padding for phones */
  }
}

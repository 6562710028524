* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Main Container */
.main-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.heading-container {
  margin-bottom: 20px;
}

.heading-container h2 {
  font-size: 24px;
}

.filter-flight {
  display: flex;
  justify-content: center;
}

.filter-container {
  display: none;

  max-width: 30%;
  margin-right: 20px;

  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 20px;
}

.filter-container h1 {
  font-size: 20px;
  margin-bottom: 20px;
}

.filter-card {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
  padding: 20px;
}

.filter-card h2 {
  font-size: 15px;
  margin-bottom: 10px;
}

.filter-card select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Flight Cards Container */
.flight-cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Flight Card */
.flight-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  /* margin-bottom: 20px;
  margin-right: 20px; */
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  width: 100%;
  height: auto;
}

/* Responsive Flight Card */

/* Other Styles */
.filter-flight {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flight-path {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  height: 50px;

}

.flight-logo_s {
  height: 150px;
  width: 170px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.flight-logo img {
  height: 50px;
  width: 50px;
  color: #007bff;
}

.f_name {
  font-size: 12px;
  justify-content: center;

}

.flight-details {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.data_fli {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5px;
  justify-content: center;
}

.to p {
  font-weight: bolder;
}

.to {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.from {
  display: flex;
  flex-direction: column;
}

.line {
  display: flex;
  align-items: center;
  padding-top: 10px;
  /* Align items vertically */
}

.line svg{
  height: 20px;
}

.icon {
  font-size: 25px;
  /* Set the size of the icons */
  margin: 0 10px;
  /* Add some space between the icons and the separator */
}

.separator {
  flex: 1;
  /* Allow the separator to grow to fill available space */
  border: none;
  /* Remove default border */
  height: 2px;
  /* Set the height of the separator */
  background-color: black;
  /* Set the color of the separator */
}

.to p,
.from p {
  font-size: 12px;
}

.to span,
.from span {
  font-size: 10px;
}

.from p {
  font-weight: bolder;
}

.stops,
.c,
.duration {
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: center;
  justify-content: center;
}

.price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex-direction: column;
  width: 130px;
}

/* flight-card.css */

/* Style for the price section */

.price-label {
  font-weight: bold;
}

.price-value {
  font-size: 12px;
}

/* Style for the radio button */
.price input[type="radio"] {
  transform: scale(1.5); /* Increase the size of the radio button */
  margin-right: 20px; /* Add more space between the radio button and other elements */
}


.price-label {
  color: #007bff;
  font-weight: bold;
  margin-right: 10px;
  align-items: center;
}

.price-value {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.animated-flight-path svg {
  align-items: center;
}

.stops span,
.c span,
.duration {
  font-size: 12px;
}

@media (max-width: 768px) {
  .main-container {
    width: 100%;
    overflow: hidden;
  }

  .heading-container h2 {
    font-size: 16px;
    /* Reduce font size for heading */
  }

  .filter-container {
    display: none;
    /* Hide filter container on mobile */
  }

  .flight-card {
    min-width: 100%;
    width: 340px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 15px;
    /* Add padding to flight card */
    margin-bottom: 10px;
    /* Reduce bottom margin */
  }

  .flight-logo_s {
    height: 30px;
    /* Reduce logo image height */
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
  }

  .flight-logo img {
    height: 30px;
    width: 30px;
  }


  .f_name {
    padding-left: 20px;
    text-align: center;
    align-items: baseline;
  }

  .flight-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Align flight details in a row */
  }

  .price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  /* flight-card.css */

  /* Style for the price section */
  .price {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price-label {
    font-weight: bold;
  }

  .price-value {
    font-size: 1em;
  }

  /* Style for the radio button */
  .price input[type="radio"] {
    transform: scale(1.5);
    /* Increase the size of the radio button */
    margin-right: 20px;
    /* Add more space between the radio button and other elements */
  }


  .data_fli {
    margin: 5px;
    text-align: center;
    flex-direction: column;
    font-size: 12px;
    /* Reduce font size for flight details */
  }


  .to span {
    text-align: right;
  }

  .from span {
    text-align: left;
  }

  .stops,
  .duration {
    margin-top: 0;
    /* Remove margin top for stops and duration */
  }

  .stops,
  .c,
  .duration {
    text-align: center;
    justify-content: center;
  }

  .flight-path {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {

  .stops,
  .c {
    font-size: 0.9rem;
    /* Decrease font size for smaller screens */
  }
}

@media (max-width: 576px) {

  .stops,
  .c {
    font-size: 0.8rem;
    /* Further decrease font size for even smaller screens */
  }
}





/* Main container */
.oneway-filter-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: auto;
}

/* Heading container */
.oneway-filter-heading-container {
  text-align: center;
  margin-bottom: 10px; /* Reduced margin */
}

/* Filter section */
.oneway-filter-filter-flight {
  display: flex;
  flex-direction: row ; 
  max-width: 100%;
}





.oneway-filter-filters {

  display: flex;
  flex-direction: column;
  padding: 10px; /* Reduced padding */
  background-color: #f9f9f9;  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.oneway-filter-filter-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
  
  /* width: 200px; */


}

.oneway-filter-filter-group h4 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #efc8a8;
  margin-bottom: 5px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* width: 200px; */


}


.oneway-filter-filter-group label {
  font-weight: bold;
  
}

.oneway-filter-filter-group select,
.oneway-filter-reset-button {
  padding: 6px; /* Reduced padding */
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
}

.oneway-filter-reset-button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.oneway-filter-reset-button:hover {
  background-color: #0056b3;
}

/* Flight cards container */
.oneway-filter-flight-cards-container {
  flex: 1 ;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap */
  margin-left: 20px;
  width: 1000px;
}

.filter-summary{
  font-size: medium;
  font-weight: 700;
}

@media (max-width: 768px) {
  .oneway-filter-filter-flight {
    flex-direction: column;
  }
  
  .oneway-filter-filters {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 10px; /* Reduced margin */

  }

  .oneway-filter-filter-group {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .oneway-filter-reset-button {
    flex: 1 1 100%;
    margin-top: 10px;
  }

  .oneway-filter-flight-cards-container {
    margin-left: 0;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin: 10px;
    align-self: center;
    align-content: center;
  }
  .oneway-filter-filters {

    width: 100%;
  }
    .oneway-filter-filter-group{
    /* background-color: #dd0000; */
    display: flex;
    flex-direction: row;
    
  }
}


@media (max-width: 400px) {

  
  .oneway-filter-filters {

    width: 100%;
  }


}






/* Filter section */
.oneway-filter-filters {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;

  /* width: 50%; */
  max-width: 400px;
  min-width:280px ;
}

.oneway-filter-filters h3, .oneway-filter-filters h4 {
  margin-bottom: 10px;
  font-size: 14px;
  color: #007bff;
}

.oneway-filter-filters label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
}

.oneway-filter-filters input[type="radio"] {
  margin-right: 5px;
}

.reset-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
}

.reset-button:hover {
  background-color: #0056b3;
}

.filter-summary {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
}

.filter-group {
  margin-bottom: 15px;
}

.filter-group h4 {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.oneway-filter-filter-flight {
  display: flex;
  gap: 20px;
}

.oneway-filter-flight-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}




.filter-content {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .filter-content {
    display: flex;
    flex-direction: column;
  }

 .oneway-filter-filters {
    display: block; /* Show the filters when the toggle is clicked */
  }

  .oneway-filter-filters.visible {
    display: none; 
  }

  .oneway-filter-filter-group {
    display: flex;
    flex-direction: column;
  }
}



/* Add these styles to your existing CSS file */

.filter-toggle {
  display: none;
  cursor: pointer;
}

.filter-content {
  display: flex;
}

.oneway-filter-filters {
  display: block;
  /* other styles for the filter section */
}

@media (max-width: 768px) {
  .filter-toggle {
    display: block;
    margin: 10px 0;
  }

  .oneway-filter-filters {
    display: none;
  }

  .oneway-filter-filters.visible {
    display: block;
  }

  .filter-content {
    flex-direction: column;
  }
}







.enhanced-section {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 12px; /* Slightly increased for a smoother look */
  background: linear-gradient(135deg, #f9f9f9, #e3e3e3); /* Gradient background */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Increased shadow for more depth */
  position: relative;
  overflow: hidden; /* Ensures child elements don't overflow */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-line {
  margin-bottom: 10px; /* Space between text and number */
  font-size: 1.2em;
}

.highlighted-number {
  color: #007BFF; /* Slightly brighter blue */
  font-weight: 800;
  font-size: 1.3em; /* Initial font size */
  text-decoration: none;
  animation: pulse 2s infinite;
  display: inline-block;
  padding: 5px 10px; /* Added padding for better clickability */
  border-radius: 5px; /* Rounded corners */
}

.highlighted-number:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: underline;
}


/* Additional subtle animations */
.enhanced-section:hover {
  border-color: #bbb; /* Slightly darker border on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .enhanced-section {
    padding: 18px;
  }
  .text-line {
    font-size: 1.1em;
  }
  .highlighted-number {
    font-size: 1.2em;
  }
}

@media (max-width: 992px) {
  .enhanced-section {
    padding: 16px;
  }
  .text-line {
    font-size: 1em;
  }
  .highlighted-number {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .enhanced-section {
    padding: 14px;
  }
  .text-line {
    font-size: 0.9em;
  }
  .highlighted-number {
    font-size: 1.2em;
  }
}

@media (max-width: 576px) {
  .enhanced-section {
    padding: 12px;
    border-radius: 8px; /* Adjusted border-radius for smaller screens */
  }
  .text-line {
    font-size: 0.8em;
  }
  .highlighted-number {
    font-size: 1.2em;
    padding: 4px 8px; /* Adjusted padding for smaller screens */
  }
}


.customize-trip-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    /* border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); */
}


.text-and-image {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}


.greeting-section, .our-promise-section, .contact-section {
    margin-bottom: 40px;
}

.text-and-icon {
    display: flex;
    align-items: center;
    gap: 20px;
}

.icon {
    font-size: 2.5em;
    color: #007BFF;
}

h1, h2, h3 {
    margin: 0 0 10px 0;
    font-weight: 100;
}

h1 {
    font-size: 1.8em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.2em;
}

p {
    margin: 0;
    font-size: 0.9em;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding-bottom: 20px;
}

.card {
    background: #ecd579;
    border-color: black;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    transition: transform 0.3s;
    
}

.card:hover {
    transform: translateY(-10px);
}

.fa-icon {
    font-size: 1.8em;
    color: #007BFF;
}

.card .text {
    flex: 1;
    text-align: justify;
}

.VgroupBookingModification-container {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.VgroupBookingModification-banner {
  background-image: url('../../Media/bge.webp');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  margin-bottom: 40px;
  border-radius: 8px;
  overflow: hidden;
}

.VgroupBookingModification-banner h1 {
  font-size: 2.5em;
  background-color: #000000b4;
  padding: 10px 20px;
  margin: 0 auto 10px;
  display: inline-block;
  border-radius: 4px;
}

.VgroupBookingModification-banner p {
  font-size: 1.5em;
  background-color: #000000b4;
  padding: 10px 20px;
  margin: 0 auto;
  display: inline-block;
  border-radius: 4px;
}

.VgroupBookingModification-container section {
  margin-bottom: 40px;
}

.VgroupBookingModification-container h2 {
  color: #333;
  border-bottom: 2px solid #0066cc;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 1.8em;
}

.VgroupBookingModification-features-grid,
.VgroupBookingModification-steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.VgroupBookingModification-feature-card,
.VgroupBookingModification-step-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.VgroupBookingModification-feature-card:hover,
.VgroupBookingModification-step-card:hover {
  transform: translateY(-5px);
}

.VgroupBookingModification-feature-card img,
.VgroupBookingModification-step-card img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.VgroupBookingModification-step-number {
  background-color: #0066cc;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.VgroupBookingModification-feature-list,
.VgroupBookingModification-info-list {
  list-style-type: none;
  padding-left: 0;
}

.VgroupBookingModification-feature-list li,
.VgroupBookingModification-info-list li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.VgroupBookingModification-feature-list li::before,
.VgroupBookingModification-info-list li::before {
  content: '•';
  color: #0066cc;
  position: absolute;
  left: 0;
  font-size: 1.2em;
}

.VgroupBookingModification-contact-button {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.VgroupBookingModification-contact-button:hover {
  background-color: #0052a3;
}

.VgroupBookingModification-footer {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

@media (max-width: 768px) {
  .VgroupBookingModification-banner {
    padding: 60px 20px;
  }

  .VgroupBookingModification-banner h1 {
    font-size: 2em;
  }

  .VgroupBookingModification-banner p {
    font-size: 1.2em;
  }

  .VgroupBookingModification-container h2 {
    font-size: 1.5em;
  }
}

@media (max-width: 480px) {
  .VgroupBookingModification-banner {
    padding: 40px 10px;
  }

  .VgroupBookingModification-banner h1 {
    font-size: 1.5em;
  }

  .VgroupBookingModification-banner p {
    font-size: 1em;
  }

  .VgroupBookingModification-container h2 {
    font-size: 1.2em;
  }

  .VgroupBookingModification-step-number {
    width: 25px;
    height: 25px;
    font-size: 0.8em;
  }
}



/* Base styles */
.booking-modification-new-content-section {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  color: #333;
}

.booking-modification-new-content-container {
  max-width: 1200px;
  margin: 0 auto;

  overflow: hidden;
  padding: 20px;
}

.booking-modification-new-content-title {
  font-size: 2rem;
  color: #1a73e8;
  text-align: center;
  margin-bottom: 20px;
}

.booking-modification-new-content-intro {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 20px;
}

.booking-modification-new-content-subtitle {
  font-size: 1.5rem;
  color: #1a73e8;
  margin: 20px 0 10px;
}

.booking-modification-new-content-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.booking-modification-new-content-list li {
  font-size: 1rem;
  line-height: 1.6;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.booking-modification-new-content-list li:last-child {
  border-bottom: none;
}

/* Utility classes for spacing */
.booking-modification-new-content-services,
.booking-modification-new-content-policies,
.booking-modification-new-content-baggage,
.booking-modification-new-content-special-services {
  margin-bottom: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .booking-modification-new-content-title {
    font-size: 1.5rem;
  }

  .booking-modification-new-content-intro,
  .booking-modification-new-content-list li {
    font-size: 0.95rem;
  }

  .booking-modification-new-content-subtitle {
    font-size: 1.2rem;
  }

  .booking-modification-new-content-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .booking-modification-new-content-title {
    font-size: 1.2rem;
  }

  .booking-modification-new-content-intro {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .booking-modification-new-content-list li {
    font-size: 0.85rem;
    line-height: 1.4;
  }

  .booking-modification-new-content-container {
    padding: 10px;
  }
}

/* Enhancements */
.booking-modification-new-content-list li strong {
  color: #555;
}



.booking-modification-new-content-section {
  transition: all 0.3s ease;
}

/* Accessibility improvements */
.booking-modification-new-content-section:focus-within {
  outline: 2px solid #1a73e8;
  outline-offset: 4px;
}

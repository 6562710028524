.book-a-flight {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .book-a-flight-hero-section {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .book-a-flight-hero-section h1 {
    font-size: 1.5rem;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .book-a-flight-hero-section p {
    font-size: 1rem;
    color: #555;
  }
  
  .book-a-flight-content-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .book-a-flight-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .book-a-flight-grid-item {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .book-a-flight-grid-item h2 {
    margin-top: 10px;
    color: #007bff;
    font-size: 0.8rem;
  }
  
  .book-a-flight-grid-item p {
    color: #555;
  }
  
  .book-a-flight-grid-item a {
    color: #007bff;
    text-decoration: none;
  }
  
  .book-a-flight-grid-item a:hover {
    text-decoration: underline;
  }
  
  .book-a-flight-image-section {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .book-a-flight-image-section img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .book-a-flight-travel-tips {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 30px;
  }
  
  .book-a-flight-travel-tips h2 {
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .book-a-flight-travel-tips ul {
    list-style: none;
    padding: 0;
  }
  
  .book-a-flight-travel-tips li {
    margin-bottom: 10px;
    font-size: .8rem;
    color: #555;
  }
  
  .book-a-flight-travel-tips li svg {
    color: #28a745;
    margin-right: 10px;
  }
  
  .book-a-flight-footer-section {
    text-align: justify;
    padding: 20px;
    background: #ffffff;
    color: #000000;
    border-radius: 8px;
  }
  
  .book-a-flight-footer-section a {
    color: #2760ff;
    text-decoration: underline;
  }
  
  .book-a-flight-footer-section a:hover {
    color: #e2e2e2;
  }
  

  









  .book-a-flight-image-section img {
    max-width: 100%;
    height: auto;
  }
  
  .book-a-flight-travel-tips {
    margin-bottom: 40px;
  }
  
  .book-a-flight-travel-tips ul {
    list-style: none;
    padding: 0;
  }
  
  .book-a-flight-travel-tips li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .book-a-flight-travel-tips li svg {
    margin-right: 10px;
  }
  
  .book-a-flight-alternate-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .book-a-flight-text {
    flex: 1;
    padding: 20px;
  }
  
  .book-a-flight-image {
    flex: 1;
    padding: 20px;
  }
  
  .book-a-flight-image img {
    width: 100%;
    height: auto;
  }
  
  .book-a-flight-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .book-a-flight-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .book-a-flight-alternate-section {
      flex-direction: column;
    }
    .book-a-flight-image, .book-a-flight-text {
      padding: 10px;
    }
  }
  


  .elementor-126 .elementor-element.elementor-element-ad9c8c4:not(.elementor-motion-effects-element-type-background), .elementor-126 .elementor-element.elementor-element-ad9c8c4 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
    background-color: transparent;
}

.american-airlines-section {
  background: #f9f9f9;
  padding: 30px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.american-airlines-section h2 {
  font-size: 2rem;
  color: #0056b3;
  margin-bottom: 15px;
}

.american-airlines-section h3 {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}

.american-airlines-section p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.american-airlines-section ul {
  padding-left: 20px;
  margin-top: 10px;
}

.american-airlines-section ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #444;
}

.book-now-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.book-now-button:hover {
  background-color: #003d82;
}

/* Utility classes */
.fa-bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}






.book-a-flight-new-1-classname-section {
  background-color: #f9f9f9;
  padding: 40px 20px;
  max-width: 1400px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.book-a-flight-new-1-classname-container {
  max-width: 1200px;
  margin: 0 auto;
}

.book-a-flight-new-1-classname-title {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.book-a-flight-new-1-classname-services {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
}

.book-a-flight-new-1-classname-subtitle {
  font-size: 1.8rem;
  color: #0073e6;
  margin-bottom: 15px;
  border-bottom: 2px solid #0073e6;
  padding-bottom: 5px;
}

.book-a-flight-new-1-classname-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.book-a-flight-new-1-classname-list li {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.book-a-flight-new-1-classname-list li:hover {
  color: #0073e6;
  transform: translateX(5px);
}

.book-a-flight-new-1-classname-list li::before {
  content: '\2022';
  color: #0073e6;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  top: 0;
}

.book-a-flight-new-1-classname-tools-title {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .book-a-flight-new-1-classname-title {
    font-size: 2rem;
  }

  .book-a-flight-new-1-classname-subtitle {
    font-size: 1.5rem;
  }

  .book-a-flight-new-1-classname-list li {
    font-size: 0.9rem;
  }

  .book-a-flight-new-1-classname-section {
    padding: 20px 10px;
  }
}

/* Container */
.landing-page-1-container {
  margin: 0 auto;
  padding: 0;
  background-color: #ffffff; /* Earthy Beige */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

/* Title */
.landing-page-1-title {
  font-size: 22px;
  color: white;
  background-color: #3498DB; /* Sky Blue */
  padding: 5px;
  text-align: center;
  font-weight: bold;
  margin: 0;
}

/* Image */
.landing-page-1-image {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 2px solid #2ECC71; /* Forest Green */
}

/* CTA Button */
.landing-page-1-cta-button {
  background-color: #3498DB;
  color: white;
  padding: 15px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: none;
  cursor: pointer;
  display: block;
  width: 95%;
  border-radius: 50px;
  margin: 10px auto;
}

.landing-page-1-cta-button:hover {
  background-color: #27AE60; /* Darker Forest Green */
}

/* Call Info */
.landing-page-1-call-info {
  background-color: #F9F9F9; /* Soft Gray */
  border-radius: 0 0 10px 10px;
  text-align: center;
  font-size: 16px;
  color: #555;
}

.landing-page-1-call-info p {
  margin: 5px 0;
  font-size: 16px;
  font-weight: 700;
}

/* Phone Box */
.landing-page-1-phone-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3498DB; /* Sky Blue */
  width: 95%;
  margin: 0 auto;
  border-radius: 50px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
}

.landing-page-1-phone-number {
  color: #FFFFFF; /* White */
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
}

.landing-page-1-phone-icon {
  font-size: 28px;
  color: #FFFFFF; /* White */
}

/* 24/7 Helpline Text */
.landing-page-1-helpline {
  font-size: 16px;
  color: #2ECC71; /* Forest Green */
  font-weight: bold;
}

/* Media Query for Mobile Only */
@media (min-width: 768px) {
  .landing-page-1-container {
    display: none;
  }
}

/* LandingPage.css */
.sec-2-landing-page-1-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 boxes in a row for desktop */
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

/* Ensure consistency across cards */
.sec-2-landing-page-1-card {
  background-color: #ffffff; /* Earthy Beige */
  border: 1px solid #3498DB; /* Sky Blue */
  border-radius: 10px;
  width: 100%;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

/* Hover effect to raise cards slightly */
.sec-2-landing-page-1-card:hover {
  transform: translateY(-10px);
}

/* Top section with a half-circle shape */
.sec-2-landing-page-1-top-circle {
  background-color: #3498DB; /* Sky Blue */
  height: 60px;
  width: 100%;
  border-bottom-left-radius: 500px;
  border-bottom-right-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styling the Font Awesome icons */
.sec-2-landing-page-1-icon {
  font-size: 1.5rem;
  color: white;
  animation: fadeIn 1s ease-in-out;
}

/* Title styles */
.sec-2-landing-page-1-title {
  font-size: 1rem;
  margin-top: 20px;
  padding: 10px;
  color: #2C3E50; /* Dark Charcoal */
}

/* Hover effect on icons */
.sec-2-landing-page-1-icon:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

/* Animation for icons on load */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design for Mobile (2 cards per row) */
@media (max-width: 768px) {
  .sec-2-landing-page-1-container {
    grid-template-columns: repeat(2, 1fr); /* 2 boxes in a row for mobile view */
  }
}

/* Responsive Design for Small Screens (1 card per row) */
@media (max-width: 480px) {
  .sec-2-landing-page-1-container {
    grid-template-columns: repeat(1, 1fr); /* 1 box per row for smaller screens */
  }
}

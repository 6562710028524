/* Default hidden */
.Navbar1-container {
  display: none;
}

/* Visible only on small/mobile screens */
@media (max-width: 768px) {
  .Navbar1-container {
    position: relative;
    width: 100%;
    background-color: #263746; /* Same color as in the image */
    text-align: center;
    padding: 10px;
    z-index: 1000;
    display: block;
  }

  .Navbar1-link {
    color: 3498DB;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Navbar1-icon {
    margin-right: 8px;
    font-size: 14px;
    animation: shake-icon 1.5s infinite ease-in-out; /* Shaking animation */
  }

  .Navbar1-text {
    margin-right: 5px;
    font-size: 14px;

  }

  .Navbar1-number {
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;

  }
}

/* Keyframe animation for showing and hiding the number digit by digit */
@keyframes reveal-number {
  0% {
    width: 0; /* Start with 0 width */
    opacity: 0;
  }
  30% {
    width: 100px; /* Adjust the width to fit the number */
    opacity: 1;
  }
  70% {
    width: 100px; /* Hold the number on screen */
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0; /* Fade and shrink back to 0 width */
  }
}

/* Keyframe animation for shaking the phone icon */
@keyframes shake-icon {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-2px);
  }
}

.date-input-container {
    position: relative;
    width: 100%;
  }
  
  .date-input-container label {
    position: absolute;
    top: -0.5rem;
    left: 2.5rem;
    background-color: white;
    padding: 0 0.25rem;
    font-size: 0.75rem;
    color: #6b7280;
    z-index: 1;
  }
  
  .date-input-container input[type="date"] {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border-radius: 0.375rem;
    border: 1px solid #e2e8f0;
    background-color: white;
    font-size: 1rem;
    color: #1a2b4b;
  }
  
  .date-input-container input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .date-input-container input[type="date"]:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
  
  /* Add these styles to your existing booking component CSS */
  .search-grid .input-group .date-input-container input[type="date"] {
    padding-right: 2rem;
  }
  
  .date-group .input-group {
    flex: 1;
    min-width: 0;
  }
  
  /* Disabled state for the search button */
  .search-button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  /* Hover state for enabled search button */
  .search-button:not(:disabled):hover {
    background-color: #059669;
  }



  
.Airline-Reservations-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 2px solid #e0e0e0;
  background-color: rgb(0, 0, 0);
  font-family: Arial, sans-serif;
  position: relative;
}

.Airline-Reservations-left {
  display: flex;
  align-items: center;
}

.Airline-Reservations-icon {
  color: #003366;
  font-size: 24px;
  margin-right: 10px;
}

.Airline-Reservations-text {
  font-size: 20px;
  color: #003366;
  font-weight: bold;
}

.Airline-Reservations-right {
  display: flex;
  align-items: center;
}

.Airline-Reservations-arc-logo {
  display: flex;
  align-items: center;
  background-color: #0099cc;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  margin-right: 15px;
}

.Airline-Reservations-arc {
  font-size: 18px;
  margin-right: 5px;
}

.Airline-Reservations-accredited {
  font-size: 12px;
  text-transform: uppercase;
}

.Airline-Reservations-message {
  font-size: 14px;
  color: #0099cc;
  font-weight: bold;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .Airline-Reservations-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }

  .Airline-Reservations-left,
  .Airline-Reservations-right {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .Airline-Reservations-icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .Airline-Reservations-text {
    font-size: 18px;
  }

  .Airline-Reservations-arc-logo {
    padding: 4px 8px;
    margin-right: 10px;
  }

  .Airline-Reservations-arc {
    font-size: 16px;
  }

  .Airline-Reservations-accredited {
    font-size: 10px;
  }

  .Airline-Reservations-message {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .Airline-Reservations-container {
    padding: 10px;
  }

  .Airline-Reservations-icon {
    font-size: 18px;
  }

  .Airline-Reservations-text {
    font-size: 16px;
  }

  .Airline-Reservations-arc-logo {
    padding: 3px 6px;
  }

  .Airline-Reservations-arc {
    font-size: 14px;
  }

  .Airline-Reservations-accredited {
    font-size: 8px;
  }

  .Airline-Reservations-message {
    font-size: 12px;
  }
}


/* Container and Background Image */
.Airline-Reservations-banner-container {
  position: relative;
  background-image: url('../../Media/8338.jpg');
  background-size: cover;
  background-position: center;
  height: 500px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Overlay */
.Airline-Reservations-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Text Content */
.Airline-Reservations-banner-content {
  text-align: center;
  padding: 20px;
}

.Airline-Reservations-banner-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.Airline-Reservations-banner-subtitle {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.Airline-Reservations-banner-contact {
  margin-bottom: 20px;
}

.Airline-Reservations-banner-contact-button {
  background-color: #d9534f;
  color: white;
  font-size: 1.25rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Airline-Reservations-banner-contact-button:hover {
  background-color: #c9302c;
}

.Airline-Reservations-banner-support-info {
  font-size: 1rem;
}

/* Hide banner on smaller screens */
@media (max-width: 1024px) {
  .Airline-Reservations-banner-container {
      display: none; /* Hide the banner for screens below 1024px */
  }
}

  







/* Mobile container */
.Airline-Reservations-banner-mobile-container {
  display: none; /* Hidden by default */
  background-color: #0c0c28; /* Dark background */
  color: #fff; /* White text */
  padding: 20px;
  text-align: center;
}

.Airline-Reservations-banner-mobile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Airline-Reservations-banner-mobile-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.Airline-Reservations-banner-mobile-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.Airline-Reservations-banner-mobile-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
}

.Airline-Reservations-banner-mobile-contact {
  margin-bottom: 20px;
}

.Airline-Reservations-banner-mobile-support {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.Airline-Reservations-banner-mobile-button {
  background-color: #d9534f;
  color: white;
  font-size: 1.5rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Airline-Reservations-banner-mobile-button:hover {
  background-color: #c9302c;
}

.Airline-Reservations-banner-mobile-links {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.Airline-Reservations-banner-mobile-links span {
  cursor: pointer;
  text-decoration: underline;
}

/* Mobile view visibility */
@media (max-width: 768px) {
  .Airline-Reservations-banner-mobile-container {
    display: block; /* Visible only on mobile */
  }
}









  /* AirlineReservationsSection.css */

.Airline-Reservations-section-1-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 10px auto;
    padding: 50px;
  }
  
  .Airline-Reservations-section-1-item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  
  .Airline-Reservations-section-1-icon {
    color: #2c3eaf; /* Blue color for icons */
    font-size: 2rem;
  }
  
  .Airline-Reservations-section-1-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
  
  .Airline-Reservations-section-1-description {
    font-size: 1rem;
    color: #666;
    margin-top: 5px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .Airline-Reservations-section-1-container {
      padding: 50px;
    }
  
    .Airline-Reservations-section-1-title {
      font-size: 1.1rem;
    }
  
    .Airline-Reservations-section-1-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .Airline-Reservations-section-1-item {
      /* flex-direction: column; */
    flex-direction: row;
      align-items: flex-start;
    }
  
    .Airline-Reservations-section-1-icon {
      font-size: 3rem;
    }
  }
  

/* AirlineReservationsSectionMobile.css */

.Airline-Reservations-section-2-container {
    display: none; /* Hide by default, only show on mobile */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .Airline-Reservations-section-2-description {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #555;
  }
  
  .Airline-Reservations-section-2-benefits {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .Airline-Reservations-section-2-benefits li {
    font-size: 0.95rem;
    margin: 5px 0;
    display: flex;
    align-items: center;
    color: #333;
  }
  
  .Airline-Reservations-section-2-benefit-icon {
    color: #2c3eaf; /* Color for icons */
    margin-right: 8px;
  }
  
  .Airline-Reservations-section-2-helpline {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9534f;
    padding: 12px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .Airline-Reservations-section-2-phone-icon {
    margin-right: 8px;
  }
  
  .Airline-Reservations-section-2-phone-number {
    font-size: 1.2rem;
  }
  
  .Airline-Reservations-section-2-helpline-info {
    text-align: center;
    font-weight: bold;
    color: #555;
  }
  
  .Airline-Reservations-section-2-agent {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the agent section */
    margin-top: 10px;
  }
  
  .Airline-Reservations-section-2-agent-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .Airline-Reservations-section-2-agent-status {
    font-size: 0.9rem;
    color: #555;
    text-align: center;
  }
  
  .Airline-Reservations-section-2-status-indicator {
    width: 8px;
    height: 8px;
    background-color: #d9534f;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  /* Mobile-only visibility */
  @media (max-width: 768px) {
    .Airline-Reservations-section-2-container {
      display: block;
    }
  }
  
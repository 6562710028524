:root {
  --footer-background-color: #000;
  --footer-text-color: #fff;
  --footer-link-color: #fff;
}

.site-footer {
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  max-width: 1200px;
  width: 100%;
}

.footer-section {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin: 10px; /* Adds space between sections */
  width: calc(25.33% - 20px); /* Two sections per row with 20px margin */
  font-size: 12px;

}

.footer-section h4 {
  margin-bottom: 16px;
  font-size: 15px;
}

.footer-section p {
  /* margin-bottom: 16px; */
  font-size: 12px;
}
.footer-section a {
  color: var(--footer-link-color);
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Smooth color transition for hover */
}

.footer-section a:hover,
.footer-section a:focus {
  color: #ccc; /* Lighten link color on hover/focus for better interaction */
}

.footer-section p {
  margin-bottom: 10px;
  text-align: justify;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between icons */
  margin-top: 20px; /* Space from the last text link */
}

.social-links a {
  color: #fff;

  font-size: 36px; /* Increase icon size */
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  width: 100%; /* Ensure full width on small screens */
}

.footer-bottom p {
  margin-bottom: 10px;
}

input[type="email"] {
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 100%; /* Full width to match container */
  margin-bottom: 20px;
  /* Add a box-shadow or border if desired */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .footer-container {
    padding: 0 5%; /* Reduce padding on smaller screens */
  }

  .footer-disclaimer{
    padding: 20px;
    justify-content: center;
    text-align: justify;
  }

  .footer-section {
    align-items: left; /* Center content on small screens */
    margin: 10px; /* Add vertical margin for stacked layout */
    width: calc(100%); /* Two sections per row with 20px margin */
    text-align: left;


  }

  .social-links {
    justify-content: left; /* Center social links on small screens */
    align-items: center;
  }

.footer-section button {
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.footer-section input[type="email"] {
  width: 200px; /* Adjust as needed */
  /* margin-right: 10px; */
}
}

.footer-section input[type="email"] {
  width: 200px; /* Adjust as needed */
  /* margin-right: 10px; */
}



.footer-disclaimer{
  max-width: 1000px;
}
.image-container {
  text-align: center;
}

.icon-image {
  width: auto;
  height: 50px;
  margin-left: 10px;
}

.centered-heading {
  text-align: center;
  font-size: 1.5rem;
}

.small-texts {
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  justify-content: center;
}

.phone-text-nub{
  color: aliceblue;
  text-decoration: none;
}
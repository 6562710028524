.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(184, 225, 211);
  }
  
  .confirmation-header {
    margin-bottom: 20px;
  }
  
  .confirmation-header img {
    max-width: 100%;
  }
  
  .confirmation-content {
    text-align: center;
  }
  
  .confirmation-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .confirmation-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
/* General Container */
.new-booking-page-container {
    /* background-image: url("background-image-url.jpg"); Replace with your image path */
    background-image: url('../../Media/bg123.jpg');

    background-size: cover;
    background-position: center;
    color: white;
    padding: 50px 20px;
    /* font-family: Arial, sans-serif; */
    font-family: ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  }
  
  /* Header Section */

  .new-booking-page-header{
    text-align: center;
  }
  .new-booking-page-header h1 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .new-booking-page-header h2 {
    font-size: 1.8rem;
    margin-top: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Tabs Section */
  .new-booking-page-tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .new-booking-page-tab {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 25px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .new-booking-page-tab.active,
  .new-booking-page-tab:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  /* Search Box */
  .new-booking-page-search-box {
    background: rgba(0,7,36,.7);
    padding: 20px 30px;
    border-radius: 15px;
    margin-top: 30px;
  }
  
  .new-booking-page-options {
    display: flex;
    gap: 20px;
    justify-content: left;
    margin-bottom: 20px;
  }
  
  .new-booking-page-radio {
    font-size: 1rem;
  }
  
  .new-booking-page-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
  }
  
  .new-booking-page-input {
    flex: 1;
    min-width: 18%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    outline: none;
    background: rgba(255, 255, 255, 0.9);
    color: #333;
  }
  
  .new-booking-page-search-button {
    background: #28a745;
    color: white;
    font-size: 1.2rem;
    border: none;
    padding: 12px 20px;
    margin-top: 15px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    transition: background 0.3s ease;
  }
  
  .new-booking-page-search-button:hover {
    background: #218838;
  }
  
  /* Footer Section */
  .new-booking-page-footer {
    margin-top: 30px;
    text-align: center;
    font-size: 1rem;
  }
  
  .new-booking-page-reviews {
    margin-top: 10px;
    font-size: 0.9rem;
  }
  
  .new-booking-page-stars {
    color: gold;
  }
  
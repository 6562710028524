/* General Styles for the Payment Form */
.payment-form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f9;
  min-height: 70vh; /* Full viewport height for centering */
}

.payment-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
  margin: 20px;
  transition: max-width 0.3s ease;
}

.payment-details {
  background-color: #e3f2fd;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #ddd;
}

.payment-details h2 {
  margin-bottom: 20px;
  color: #333;
}

.payment-method {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.payment-icon img {
  max-width: 100%;
  width: 300px;
  height: auto;
  margin-right: 15px;
}

.payment-description h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.payment-description p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.payment-form-section {
  padding: 20px;
}

.payment-form-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-groups {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-groups label {
  margin-bottom: 5px;
  color: #333;
}

.form-groups input,
.form-groups textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-groups input:focus,
.form-groups textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-groups input::placeholder,
.form-groups textarea::placeholder {
  color: #aaa;
}

.form-groups-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-groups-row .form-groups {
  flex: 1;
}

.submit-button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.secure-payment {
  text-align: center;
  margin-top: 10px;
  color: #007bff;
  font-size: 14px;
}

/* Billing Section Styles */
.billing-section {
  margin-top: 20px;
}

.billing-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.billing-section .form-groups {
  margin-bottom: 15px;
}

.billing-section .form-groups-row {
  display: flex;
  justify-content: space-between;
}

.billing-section .form-groups-row .form-groups {
  flex: 1;
  margin-right: 10px;
}

.billing-section .form-groups-row .form-groups:last-child {
  margin-right: 0;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .payment-container {
    grid-template-columns: 1fr;
    max-width: 800px;
  }

  .payment-details {
    border-right: none;
    border-bottom: 1px solid #ddd;
    text-align: center;
    align-items: center;
  }

  .payment-method {
    justify-content: center;
  }

  .form-groups-row {
    flex-direction: column;
  }

  .form-groups {
    width: 100%;
  }

  .form-groups-row .form-groups {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .submit-button {
    padding: 12px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .payment-container {
    max-width: 600px;
  }

  .form-groups input,
  .form-groups textarea {
    font-size: 14px;
    padding: 10px;
  }

  .form-groups label {
    font-size: 14px;
  }

  .submit-button {
    padding: 10px;
    font-size: 14px;
  }

  .secure-payment {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .payment-container {
    max-width: 100%;
    margin: 10px;
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .payment-details {
    width: 100%;
    text-align: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .payment-form-section {
    width: 100%;
  }

  .payment-icon img {
    width: 200px;
    margin-right: 0;
  }

  .form-groups input,
  .form-groups textarea {
    font-size: 12px;
    padding: 8px;
  }

  .form-groups label {
    font-size: 12px;
  }

  .submit-button {
    padding: 8px;
    font-size: 12px;
  }

  .secure-payment {
    font-size: 10px;
  }

  .billing-section .form-groups-row {
    flex-direction: column;
  }

  .billing-section .form-groups-row .form-groups {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

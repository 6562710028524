.whyChoose-us-container {
    padding: 20px;
    max-width: 1500px;
    margin: 0 auto;
    background-color: transparent;
    border-radius: 12px;
    color: #333;
}

.whyChoose-section-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 1.5em;
    color: #000000;
    font-family: 'Times New Roman', Times, serif;
}

.whyChoose-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
}

.whyChoose-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow:  4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.whyChoose-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.whyChoose-fa-icon {
    font-size: 1.5em;
    color: #007BFF;
}

.whyChoose-text h3 {
    margin-top: 10px;
    font-size: 1em;
    color: #090c10;
}

.whyChoose-text p {
    margin: 0;
    font-size: 0.8em;
    color: #666;
}

.cta-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .cta-popup {
    position: relative;
    background-color: #ffffff;
    width: 100%;
    max-width: 400px;
    /* height: 80vh; */
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  /* Header Styles */
  .cta-popup__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0rem;
  }
  
  .cta-popup__brand {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .cta-popup__logo {
    width: 7rem;
    height: 2.5rem;
  }
  
  .cta-popup__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .cta-popup__title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .cta-popup__close {
    padding: 0.5rem;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .cta-popup__close:hover {
    transform: scale(1.1);
  }
  
  .cta-popup__close-icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke: #666666;
    stroke-width: 2;
    fill: none;
  }
  
  /* Airlines Section */
  .cta-popup__airlines {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .cta-popup__airlines-text {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .cta-popup__airplane-image {
    width: 100%;
    height: 10rem;
    margin-bottom: 1rem;
  }
  
  .cta-popup__airplane-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  /* Action Buttons */
  .cta-popup__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .cta-popup__action-button {
    padding: 0.75rem 1rem;
    background-color: #115e59;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .cta-popup__action-button:hover {
    background-color: #134e4a;
    transform: translateY(-2px);
  }
  
  /* Agent Section */
  .cta-popup__agent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: .5rem;
  }
  
  .cta-popup__agent-image {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  
  .cta-popup__agent-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cta-popup__agent-text {
    font-size: 0.875rem;
    color: #666666;
  }
  
  /* Call Button */
  .cta-popup__call-button {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 10px;
    background-color: #f97316;
    color: white;
    text-decoration: none;
    border-radius: 9999px;
    transition: all 0.2s;
  }
  
  .cta-popup__call-button:hover {
    background-color: #ea580c;
    transform: translateY(-2px);
  }
  
  .cta-popup__phone-icon,
  .cta-popup__chevron-icon {
    width: 1.25rem;
    height: 1.25rem;
    stroke: currentColor;
    stroke-width: 2;
    fill: none;
  }
  
  .cta-popup__call-button span {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  /* Media Query for Desktop */
  @media (min-width: 768px) {
    .cta-popup-overlay {
      display: none;
    }
  }
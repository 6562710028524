/* General Styles */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0; /* Remove default margin for headings */
}

p {
  line-height: 1.6; /* Increase line height for better readability */
}

/* Homepage Features */
.homepage-features {
  text-align: center;
  padding: 10px 0;
}

.homepage-features-heading {
  margin-bottom: 30px;
  font-size: 16px; /* Smaller font size */
  font-weight: 600;

}

.homepage-feature-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homepage-feature-box {
  width: calc(60% - 10px); /* Two boxes per row for smaller screens */
  max-width: 250px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.homepage-feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.homepage-feature-icon {
  margin-bottom: 20px;
  color: #2a89bc;
}

.homepage-feature-heading {
  margin-bottom: 10px;
  font-size: 15px; /* Smaller font size */
  color: #0056b3;
  font-weight: 500;
}

.homepage-feature-description {
  font-size: 13px; /* Smaller font size */
}

@media only screen and (min-width: 768px) {
  .homepage-feature-box {
    width: calc(100% - 10px); /* Four boxes per row for larger screens */
    max-width: 300px;
    padding: 10px;
    margin: 10px;
  }
}

@media only screen and (min-width: 320px) {
  .homepage-feature-box {
    width: calc(45% - 10px); /* Adjust width for smaller screens */
    padding: 10px;
    margin: 10px;
  }
}




/* FAQs Section */
.homepage-faqs-section-mendat {
  text-align: left;
  max-width: 1200px; /* Set maximum width */
  margin: 0 auto 50px; /* Center the section and add bottom margin of 50px */
  padding: 0 20px; /* Add padding to maintain spacing */
}

.homepage-faqs-section-heading-mendat {
  margin-bottom: 30px;
  font-size: 24px; /* Smaller font size */
  text-align: center; /* Center align the heading */
}

.homepage-faq-question-mendat {
  background-color: #f9f9f9;
  color: #333;
  border: none;
  padding: 15px;
  width: 100%; /* Adjusted width for the question */
  margin: 0;
  text-align: left;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.homepage-faq-question-mendat:hover,
.homepage-faq-question-mendat.active {
  background-color: #e0e0e0;
}

.homepage-faq-answer-mendat {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  transition: max-height 0.3s ease, padding 0.3s ease; /* Animation for opening and closing the answer */
}

.homepage-faq-answer-mendat.active {
  max-height: 1000px; /* Adjust to a sufficiently large value */
  padding: 10px 15px; /* Add padding to the opened answer */
}

/* Testimonials Carousel Section */
.testimonials-section {
  background-color: #000; /* Set background color to black */
  padding: 20px 10px; /* Add padding for spacing */
  text-align: center;
}

.testimonials-heading {
  background-color: #000;
  color: aliceblue;
  text-align: center;
  font-size: 22px; /* Smaller font size */
}

/* Testimonial Card Styling */
.testimonial-card {
  background-color: #000000; /* Set card background color to dark gray */
  border: 2px solid #3e3d3d; /* Apply lightish border */
  border-radius: 10px; /* Round the corners of the card */
  margin-bottom: 30px; /* Add larger margin between cards */
  transition: transform 0.3s ease; /* Add transition effect */
  color: aliceblue;
  max-width: 90%;
  padding: 20px;
}

/* Testimonial Card Content */
.testimonial-card h3 {
  margin-bottom: 10px; /* Add margin below client name */
  text-align: center;
  font-size: 18px; /* Smaller font size */
}

/* Testimonial Card Image */
.testimonial-card img {
  border-radius: 50%; /* Make the image circular */
  width: 120px; /* Set image width */
  height: 120px; /* Set image height */
  margin: 0 auto 15px; /* Add margin for spacing */
  display: block; /* Center the image horizontally */
}

/* Testimonial Card Rating */
.testimonial-card .testimonial-rating {
  margin-bottom: 10px; /* Add margin below rating */
  text-align: center;
}

/* Testimonial Card Message */
.testimonial-card p {
  font-size: 14px; /* Smaller font size */
  line-height: 1.5; /* Set line height for readability */
  text-align: center;
}

/* Highlighted Card */
.testimonial-card.highlighted {
  transform: scale(1.1); /* Increase size of highlighted card */
}

/* Customised Service Overview */
.homepage-customised-service-overview-container {
  max-width: 1200px;
  margin: 0 auto;
}

.homepage-customised-service-overview {
  background-color: #fff; /* Set background color to white */
  color: #000; /* Set text color to black */
  padding: 10px 0; /* Add padding for spacing */
}

.homepage-customised-service-overview-banner {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.homepage-customised-service-overview-banner-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.homepage-customised-service-overview-banner-image {
  flex: 1;
  max-width: 30%;
  padding: 20px;
}

.homepage-customised-service-overview-banner-heading {
  margin-top: 0;
  text-align: center;
  font-size: large; /* Smaller font size */
}

.homepage-customised-service-overview-banner-content p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: 12px; /* Smaller font size */
}

.homepage-customised-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}

.homepage-customised-button:hover {
  background-color: #0056b3;
}

.homepage-customised-service-overview-banner-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .homepage-customised-service-overview-banner {
    flex-direction: column;
  }

  .homepage-customised-service-overview-banner-content,
  .homepage-customised-service-overview-banner-image {
    max-width: 100%;
  }
}

/* Holidays Customised Service Overview */
.page-holidays-customised-service-overview-container {
  max-width: 1200px;
  margin: 0 auto;
}

.page-holidays-customised-service-overview {
  background-color: #fff; /* Set background color to white */
  color: #000; /* Set text color to black */
  padding: 10px 0; /* Add padding for spacing */
}

.page-holidays-customised-service-overview-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.page-holidays-customised-service-overview-banner-content {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.page-holidays-customised-service-overview-banner-image {
  flex: 1;
  max-width: 30%;
  padding: 20px;
}

.page-holidays-customised-service-overview-banner-heading {
  margin-top: 0;
  text-align: center;
  font-size: large; /* Smaller font size */
}

.page-holidays-customised-service-overview-banner-content p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: 12px; /* Smaller font size */
}

.page-holidays-customised-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  display: block; /* Ensure the button behaves as a block element */
  margin: 0 auto; /* Center the button horizontally */
}

.page-holidays-customised-button:hover {
  background-color: #0056b3;
}

.page-holidays-customised-service-overview-banner-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .page-holidays-customised-service-overview-banner {
    flex-direction: column;
  }

  .page-holidays-customised-service-overview-banner-content,
  .page-holidays-customised-service-overview-banner-image {
    max-width: 100%;
  }
}

/* Our Promises Section */
.home-page-our-promises {
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
}

.home-page-our-promises-heading {
  margin-bottom: 50px;
  font-size: 24px; /* Smaller font size */
}

.home-page-our-promises-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto; /* Center horizontally */
}

.home-page-our-promises-box {
  width: calc(45% - 10px); /* Two boxes per row for smaller screens */
  max-width: 300px;
  padding: 10px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.home-page-our-promises-icon {
  margin-bottom: 20px;
  margin-top: 20px;
  color: #007bff;
}

.home-page-our-promises-heading {
  margin-bottom: 10px;
  font-size: 18px; /* Smaller font size */
}

.home-page-our-promises-description {
  font-size: 14px; /* Smaller font size */
}

/* Video Background with Booking Form Component */
.video-background-form {
  position: relative;
  width: 100%;
  height: 100vh; /* Set height to full viewport height */
  overflow: hidden; /* Hide overflowing content */
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: -1; 
}

/* Form overlay */
.form-overlay {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the form */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  width: 80%; /* Adjust the width as needed */
  max-width: 400px; /* Limit maximum width */
  text-align: center;
}

/* Form input fields */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block; /* Display labels as block elements */
  margin-bottom: 5px;
  color: rgb(255, 254, 254); /* Set label text color */
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(206, 194, 194, 0.672); /* Semi-transparent background */
  color: rgb(0, 0, 0); /* Set input text color */
}

/* Form submit button */
.form-group button {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
}

.form-group button:hover {
  background-color: #0056b3;
}












.home-dial-us-container {

  text-align: center;
  margin: auto;
  padding: 20px;
}

.home-dial-us-text {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 800;
  color: #333;
}

.home-dial-us-call {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 2px solid #00ff11;
  border-radius: 5px;
  background-color:transparent;
  cursor: pointer;
  color: #ccc;
}



.home-dial-us-icon {
  color: #ffffff;
  margin-right: 10px;
  font-size: 0.9em;
}

.home-dial-us-number {
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
}





.flight-reservations-assistance-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  flex-wrap: wrap;
}

.flight-reservations-assistance-image-container,
.flight-reservations-assistance-text-container {
  flex: 1 1 50%;
  max-width: 50%;
  box-sizing: border-box;
}

.flight-reservations-assistance-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flight-reservations-assistance-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.flight-reservations-assistance-title {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.flight-reservations-assistance-text {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

/* Responsive styles */
@media (max-width: 768px) {
  .flight-reservations-assistance-container {
    flex-direction: column;
  }

  .flight-reservations-assistance-image-container,
  .flight-reservations-assistance-text-container {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .flight-reservations-assistance-image {
    margin-bottom: 20px;
  }
}

/* CookieBanner.css */

.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  z-index: 9998; /* Ensures it's above everything else */
  pointer-events: none; /* Makes the overlay non-clickable */
}

.cookie-banner {
  position: fixed;
  bottom: 0; /* Position at the bottom of the page */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  width: 100%; /* Full width by default */
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999; /* Ensures it's above the overlay */
  border-radius: 8px 8px 0 0; /* Rounded top corners */
}

.cookie-banner p {
  margin: 0;
  display: inline-block;
}

.cookie-banner a {
  color: #1e90ff;
  text-decoration: underline;
}

.accept-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px; /* Optional: Rounded button */
}

.accept-button:hover {
  background-color: #45a049;
}

/* Make the overlay and banner responsive */
@media (max-width: 600px) {
  .cookie-banner {
    width: 100%;
    padding: 15px;
    border-radius: 0; /* Remove rounded corners on mobile */
  }
}

.plane-paradise-container {
  position: relative;
  text-align: center;
  color: white;
}

.plane-paradise-heading-outside {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.plane-paradise-background-image {
  width: 100%;
  height: 80vh;
}

.plane-paradise-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.6); /* Increased opacity for better readability */
  padding: 30px; /* Increased padding for more spacing */
  border-radius: 15px; /* Slightly more rounded corners */
}

.plane-paradise-title {
  font-size: 1.4rem; /* Slightly larger font size */
  margin-bottom: 15px; /* More space below the title */
  margin-top: 10px;
}

.plane-paradise-subtitle {
  font-size: 1.2rem; /* Slightly larger subtitle font */
  margin-bottom: 20px; /* More space below the subtitle */
}

.plane-paradise-button {
  background-color: #ff9900;
  color: white;
  border: none;
  padding: 12px 24px; /* Increased padding for a larger button */
  font-size: 1.1rem; /* Slightly larger font size */
  cursor: pointer;
  border-radius: 8px; /* More rounded corners */
}

.plane-paradise-button:hover {
  background-color: #e68a00;
}

.plane-paradise-content {
  background: white;
  color: black;
  padding: 25px;
  border-radius: 10px;
  margin: 20px auto;
  max-width: 1200px;
  text-align: left;
}

.plane-paradise-content h2 {
  color: #0056b3;
  margin-top: 10px;
}

.plane-paradise-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .plane-paradise-background-image {
    width: 100%;
    height: 90vh;
  }
  .plane-paradise-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(0, 0, 0, 0.6); /* Increased opacity for better readability */
    padding: 30px; /* Increased padding for more spacing */
    border-radius: 15px; /* Slightly more rounded corners */
    /* margin: 10px; */
  }
  
}




.image-taap {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.image-taap img {
  max-width: 80%;
  height: auto;
  border-radius: 8px;
}


@media (max-width: 600px){
  .image-taap img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

}


/* Ensure this section is visible only on mobile */
@media (min-width: 768px) {
  .ecperdoa-2-1-section {
    display: none;
  }
}

.ecperdoa-2-1-section {
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
}

.ecperdoa-2-1-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.ecperdoa-2-1-logo img {
  width: 100px;
  margin-bottom: 20px;
}

.ecperdoa-2-1-title h2 {
  color: #b23333;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.ecperdoa-2-1-button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}

.ecperdoa-2-1-button {
  background-color: #006994;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ecperdoa-2-1-button:hover {
  background-color: #005778;
}

.ecperdoa-2-1-footer h3 {
  font-size: 1.2rem;
  margin-top: 20px;
}

.ecperdoa-2-1-footer p {
  font-size: 0.9rem;
}


/* Ensure this section is visible only on mobile */
@media (min-width: 768px) {
  .ecperdoa-2-1-0-section {
    display: none;
  }
}

.ecperdoa-2-1-0-section {
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  text-align: center;
}

.ecperdoa-2-1-0-container {
  max-width: 400px;
  margin: 0 auto;
}

.ecperdoa-2-1-0-image img {
  width: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.ecperdoa-2-1-0-text p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.ecperdoa-2-1-0-click h3 {
  font-size: 1.3rem;
  color: red;
  margin-bottom: 15px;
}

.ecperdoa-2-1-0-phone-button button {
  background-color: #d9534f;
  color: white;
  padding: 15px 10px;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  flex-direction: column-reverse;
}

.ecperdoa-2-1-0-phone-icon {
  margin-right: 10px;
}

.ecperdoa-2-1-0-phone-button strong {
  font-size: 1.4rem;
  margin-left: 10px;
}

.ecperdoa-2-1-0-footer p {
  font-size: 0.9rem;
  margin-top: 15px;
}

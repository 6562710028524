/* UpgradeOption.css */

/* Container styles */
.UpgradeOption-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Banner styles */
  .UpgradeOption-banner {
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .UpgradeOption-banner h1 {
    font-size: 2.5rem;
    margin: 0;
    background-color: #000000bb;
    border-radius: 10px;
    padding: 10px;
  }
  
  /* Section styles */
  .UpgradeOption-container section {
    margin-bottom: 40px;
  }
  
  .UpgradeOption-container section h2,
  .UpgradeOption-container section h3,
  .UpgradeOption-container section h4 {
    margin-bottom: 20px;
  }
  
  .UpgradeOption-container section p {
    line-height: 1.6;
  }
  
  /* Icon text styles */
  .UpgradeOption-icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .UpgradeOption-icon-text svg {
    margin-right: 10px;
  }
  
  /* Upgrade option styles */
  .UpgradeOption-upgrade-option {
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .UpgradeOption-upgrade-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .UpgradeOption-upgrade-option img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .UpgradeOption-upgrade-option h6 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .UpgradeOption-upgrade-option p {
    margin: 10px 0;
    color: #666;
  }
  
  /* Grid styles */
  .UpgradeOption-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* CTA button styles */
  .UpgradeOption-cta {
    text-align: center;
    margin-top: 20px;
  }
  
  .UpgradeOption-cta button {
    background-color: #1976d2;
    color: white;
    padding: 12px 24px;
    font-size: 1.2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .UpgradeOption-cta button:hover {
    background-color: #1565c0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .UpgradeOption-banner {
      height: 300px;
    }
  
    .UpgradeOption-banner h1 {
      font-size: 2rem;
    }
  
    .UpgradeOption-upgrade-option {
      padding: 16px;
    }
  
    .UpgradeOption-upgrade-option h6 {
      font-size: 1.2rem;
    }
  
    .UpgradeOption-cta button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  }
  
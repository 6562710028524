/* CustomerServicePage.css */
.customer-service-page {
  font-family: Arial, sans-serif;
  color: #333;
}

.customer-service-title {
  color: #0056b3;
}

.customer-service-subtitle {
  font-style: italic;
}

.customer-service-section {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.customer-service-section h2 {
  color: #0056b3;
}

.customer-service-section ul {
  list-style-type: none;
  padding-left: 0;
}

.customer-service-section ul li {
  margin-bottom: 10px;
}

.customer-service-footer {
  margin-top: 40px;
  padding: 20px;
  background-color: #f8f9fa;
}

.customer-service-contact-info {
  margin: 5px 0;
}

.customer-service-card {
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.customer-service-card-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #0056b3;
  margin-bottom: 15px;
}

.customer-service-icon {
  font-size: 30px;
  margin-bottom: 10px;
}

.customer-service-contact {
  background-color: #f8f9fa;
  padding: 40px 0;
}

.customer-service-contact-icon {
  color: #0056b3;
}

.new-content-section {
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.new-content-section h2 {
  color: #0056b3;
}

.new-content-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.new-content-section ul li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .customer-service-card {
    margin-bottom: 20px;
  }
  .new-content-section {
    padding: 20px;
  }
}

/* AirlineReservationsSearch.css */

.Airline-Reservations-search-container {
    background-color: #0c0c28;
    color: #fff;
    padding: 20px;
  }
  
  .Airline-Reservations-search-menu {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .Airline-Reservations-search-menu-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 11px;
    color: #ccc;
    cursor: pointer;
  }
  
  .Airline-Reservations-search-menu-icon {
    font-size: 20px;
    margin-bottom: 5px;
  }
  
  .Airline-Reservations-search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    background-color: #1a1a3d;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  .Airline-Reservations-search-field {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Airline-Reservations-search-label {
    font-size: 12px;
    color: #ccc;
  }
  
  .Airline-Reservations-search-input,
  .Airline-Reservations-search-select {
    background: none;
    border: none;
    color: #fff;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
    text-align: center;
  }
  
  .Airline-Reservations-search-input::placeholder {
    color: #666;
  }
  
  .Airline-Reservations-search-calendar-icon,
  .Airline-Reservations-search-user-icon {
    font-size: 18px;
    color: #ccc;
  }
  
  .Airline-Reservations-search-button {
    background-color: #e0005a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .Airline-Reservations-search-button:hover {
    background-color: #cc0050;
  }
  
  /* Offline message styling */
  .Airline-Reservations-search-message {
    margin-top: 20px;
    background-color: #ffeded;
    color: #900;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
  }
  
  .Airline-Reservations-call-button {
    display: inline-flex;
    align-items: center;
    background-color: #e0005a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .Airline-Reservations-call-button:hover {
    background-color: #cc0050;
  }
  
  .Airline-Reservations-call-icon {
    margin-right: 8px;
  }
  
  /* Mobile view specific styling */
  @media (max-width: 768px) {
    .Airline-Reservations-search-container {
      width: 100%;
      margin: 0 auto;
      padding: 20px 10px;
    }
  
    .Airline-Reservations-search-bar {
      flex-direction: column;
      gap: 10px;
      padding: 15px;
    }
  
    .Airline-Reservations-search-field {
      width: 100%;
      align-items: flex-start;
    }
  
    .Airline-Reservations-search-label {
      font-size: 16px;
      color: #e0e0e0;
      text-align: left;
      margin-bottom: 5px;
    }
  
    .Airline-Reservations-search-input,
    .Airline-Reservations-search-select {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      text-align: left;
      background-color: #0c0c28;
    }
  
    .Airline-Reservations-search-calendar-icon,
    .Airline-Reservations-search-user-icon {
      font-size: 16px;
      margin-left: 5px;
    }
  
    .Airline-Reservations-search-button {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      background-color: #ff002f;
      border-radius: 4px;
    }
  }
  
.travelers-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #1a2b4b;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6b7280;
}

.modal-body {
  padding: 1rem;
}

.traveler-section {
  margin-bottom: 1.5rem;
}

.traveler-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.traveler-info h4 {
  margin: 0;
  font-size: 1rem;
  color: #1a2b4b;
}

.traveler-info span {
  font-size: 0.875rem;
  color: #6b7280;
}

.counter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.counter-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.25rem;
  color: #1a2b4b;
}

.counter-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.counter span {
  min-width: 20px;
  text-align: center;
}

.cabin-class {
  margin-top: 2rem;
}

.cabin-class h4 {
  margin: 0 0 1rem 0;
  color: #1a2b4b;
}

.cabin-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.cabin-button {
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background: white;
  color: black;
  cursor: pointer;
  transition: all 0.2s;
}

.cabin-button.selected {
  background: #8b5cf6;
  color: rgb(0, 0, 0);
  border-color: #8b5cf6;
}

.modal-footer {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
}

.done-button {
  background: #10b981;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.done-button:hover {
  background: #059669;
}
/* ContactPage component styles */

/* Container styling */
.contact_us_container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Form input label styling */
.contact_us_label {
  display: block;
  margin-bottom: 10px;
}

/* Input field styling */
.contact_us_container input[type="text"],
.contact_us_container input[type="email"],
.contact_us_container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Textarea styling */
.contact_us_container textarea {
  height: 150px;
}

/* Button styling */
.contact_us_submit_button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact_us_submit_button:hover {
  background-color: #0056b3;
}

/* Contact details styling */
.contact_details_container {
  margin-top: 40px;
}

/* Additional information styling */
.additional_info_container {
  margin-top: 40px;
}

/* Location styling */
.location_container {
  margin-top: 40px;
}

/* Images styling */
.images_container {
  margin-top: 40px;
}

/* Responsive layout */
@media only screen and (max-width: 600px) {
  /* Adjust the layout for smaller screens */
  .contact_us_container {
    padding: 10px;
  }

  .contact_us_container input[type="text"],
  .contact_us_container input[type="email"],
  .contact_us_container textarea {
    margin-bottom: 10px;
  }
}


/* ContactPage component styles */

/* Contact details container */
.contact_us_details,
.contact_us_location,
.contact_us_info,
.contact_us_images {
  margin-top: 40px;
}

/* Contact details styling */
.contact_us_details h3,
.contact_us_location h3,
.contact_us_info h3,
.contact_us_images h3 {
  color: #007bff;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.contact_us_details p,
.contact_us_location p,
.contact_us_info p,
.contact_us_images p {
  margin-bottom: 10px;
}

/* Additional Information styling */
.contact_us_info p {
  color: #555;
}


.contact-page-phone{
  color: black;
  text-decoration: none;
}